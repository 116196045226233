import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

/* Services */

import { AutomacaoEventoService } from '../../core/services/automacao-eventos/automacao-evento.service';
import { CallCenterService } from '../../core/services/call-center/call-center.service';
import { CallCenterDiscadorService } from '../../core/services/call-center-discador/call-center-discador.service';
import { CanaisComunicacaoTelefoniaService } from '../../core/services/canais-comunicacao/telefonia/canais-comunicacao-telefonia.service';
import { CentralAtendimentoService } from '../../core/services/central-atendimento/central-atendimento.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { ConfiguracaoService } from '../../core/services/configuracoes/configuracao.service';
import { ConversaoService } from '../../core/tools/conversao.service';
import { ExcelService } from '../../core/tools/excel.service';
import { FormatacaoService } from '../../core/tools/formatacao.service';
import { NotaFiscalService } from '../../core/services/notas-fiscais/nota-fiscal.service';
import { PedidoItensService } from '../../core/services/pedidos/pedido-itens.service';
import { PedidoService } from '../../core/services/pedidos/pedido.service';
import { ProdutoService } from '../../core/services/produtos/produto.service';
import { TempoProgressivoService } from '../../core/tools/tempo-progressivo.service';
import { TempoRegressivoService } from '../../core/tools/tempo-regressivo.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';
import { VisitaService } from '../../core/services/visitas/visita.service';

/* Models */

import { AutomacaoEvento } from '../../core/models/automacao-eventos/automacao-evento';
import { AutomacaoEnvio } from '../../core/models/automacao-eventos/automacao-envio';
import { CallCenterAgendamento } from '../../core/models/call-center/call-center-agendamento';
import { CallCenterConfiguracao } from '../../core/models/call-center/call-center-configuracao';
import { CallCenterComparativoVendaProduto } from '../../core/models/call-center/call-center-comparativo-venda-produto';
import { CallCenterLigacaoAnalitica } from '../../core/models/call-center/call-center-ligacao-analitica';
import { CallCenterQualificacaoQuestao } from '../../core/models/call-center/call-center-qualificacao-questao';
import { CallCenterQualificacaoQuestaoResposta } from '../../core/models/call-center/call-center-qualificacao-questao-resposta';
import { CallCenterResultado } from '../../core/models/call-center/call-center-resultado';
import { CallCenterStatus } from '../../core/models/call-center/call-center-status';
import { CentralAtendimentoEvento } from '../../core/models/central-atendimento/central-atendimento-evento';
import { Cliente } from '../../core/models/clientes/cliente';
import { ClienteTabela } from '../../core/models/clientes/cliente-tabela';
import { Configuracao } from '../../core/models/configuracoes/configuracao';
import { NotaFiscalSintetica } from '../../core/models/notas-fiscais/nota-fiscal-sintetica';
import { Pedido } from '../../core/models/pedidos/pedido';
import { PedidoItensSintetico } from '../../core/models/pedidos/pedido-itens-sintetico';
import { PedidoSintetico } from '../../core/models/pedidos/pedido-sintetico';
import { ProdutoCesta } from '../../core/models/produtos/produto-cesta';
import { Visita } from '../../core/models/visitas/visita';

@Component({
  selector: 'app-call-center-ligacao',
  templateUrl: './call-center-ligacao.component.html',
  styleUrls: ['./call-center-ligacao.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class CallCenterLigacaoComponent implements OnInit {
  /* Emitter */

  eventosEmitter: Subscription = null;
  redirecionarPorTempoEmitter: Subscription = null;

  /* Carregamento */

  carregando: boolean = false;

  carregadoCesta: boolean = false;
  carregadoPedidos: boolean = false;
  carregadoQualificacao: boolean = false;
  carregadoVisitas: boolean = false;

  /* Acompanhamento (Gráfico de Venda Mensal) */

  mesComMelhorVenda: string = "";
  valorMesComMelhorVenda: number = 0;
  qtdeMediaMensal: number = 0;

  /* Comparativo de Vendas por Produto (6 Meses) */

  comparativoAnoPassadoDtInicial: Date = null;
  comparativoAnoPassadoAnoDtFinal: Date = null;

  comparativoEsteAnoDtInicial: Date = null;
  comparativoEsteAnoDtFinal: Date = null;

  callCenterComparativoVendasProdutos: CallCenterComparativoVendaProduto[] = [];

  comparativoAnoPassadoTotalGanhoPerdaValor: number = 0;
  comparativoEsteAnoTotalGanhoPerdaValor: number = 0;
  totalComparativoGanhoPerdaValor: number = 0;

  comparativoAnoPassadoTotalGanhoPerdaLitragem: number = 0;
  comparativoEsteAnoTotalGanhoPerdaLitragem: number = 0;
  totalComparativoGanhoPerdaLitragem: number = 0;

  /* Dados */

    /* Call Center (Agendamento) */

    callCenterAgendamentos: CallCenterAgendamento[] = [];

    callCenterAgendamentoSelecionado: CallCenterAgendamento = null;

    alterarLigacaoData: { year: number, month: number, day: number } = null;
    alterarLigacaoDataInvalida: boolean = false;
    alterarLigacaoHora: { hour: number, minute: number } = null;

    /* Call Center (Configurações) */

    callCenterConfiguracao: CallCenterConfiguracao = null;

    /* Call Center (Ligação) */

    ligacao: CallCenterLigacaoAnalitica = null;
    ligacaoFutura: CallCenterLigacaoAnalitica = null;

    /* Call Center (Resultados) */

    resultados: CallCenterResultado[] = []

    resultadoSelecionado: CallCenterResultado = null;

    /* Call Center (Status) */

    status: CallCenterStatus[] = [];

    statusSelecionado: CallCenterStatus = null;

    /* Cliente */

    cliente: Cliente = null;

    insideSales: boolean = false;
    contatoCliente: string = "";
    telefone_2: string = "";
    telefone_3: string = "";
    dddWhatsApp: string = "";
    telefoneWhatsApp: string = "";
    email_02: string = "";
    observacaoCliente: string = "";

    /* Cliente (Tabela) */

    tabelasClientes: ClienteTabela[] = [];

    /* Configurações */

    configuracao: Configuracao = null;

    /* Grupos Estoque */

    gruposEstoque: string[] = [];

    /* Qualificação (Questões) */

    callCenterQualificacaoQuestoes: CallCenterQualificacaoQuestao[] = [];
    callCenterQualificacaoQuestoesRespostas: CallCenterQualificacaoQuestaoResposta[] = [];

    /* Produtos (Cesta) */

    produtosCesta: ProdutoCesta[] = [];

    /* Visita */

    visita: Visita = null;
    visitas: Visita[] = [];

    visitaSelecionada: Visita = null;

    nroVisita: number = 0;

    observacaoVisita: string = "";

    /* Externo */

      /* Cliente Consulta (Externo) */

      clienteConsultaSelecionadoExterno: number = 0;

      /* Pedido (Externo) */

      pedidoSelecionadoExterno: number = 0;

      pedidoSelecionadoExternoComVendaRealizada: boolean = false;

      /* Vendedor (Externo) */

      vendedorSelecionadoExterno: number = 0;

      /* Visita (Externa) */

      visitaSelecionadaExterna: number = 0;

  /* Formulário (Qualificação (Questões)) */

  formQualificacaoQuestoes: FormGroup;

  qualificacaoQuestoesEnviado: boolean = false;

  /* Gráfico de Venda por ? */

  graficoVendaProdutosSelecionado: string = "Marca";

  barChartOptionsProdutos: ChartOptions = {
    animation: {
      duration: 0
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            borderDash: [5],
            lineWidth: 1.4
          },
          ticks: {
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)"
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        align: "top",
        anchor: "start",
        color: "rgba(0, 0, 0, 1)",
        font: {
          weight: "bold"
        }
      }
    }
  };

  barChartLabelsProdutos: Label[] = [];
  barChartLabelsProdutosPorMarca: Label[] = [];
  barChartLabelsProdutosPorProduto: Label[] = [];

  barChartTypeProdutos: ChartType = "bar";
  barChartLegendProdutos = false;
  barChartPluginsProdutos = [pluginDataLabels];

  barChartDataProdutos: ChartDataSets[] = [
    { data: [] }
  ];

  barChartDataProdutosPorMarca: number[] = [];
  barChartDataProdutosPorProduto: number[] = [];

  lineChartColorsProdutos: Array<any> = [
    {
      backgroundColor: "rgba(0, 123, 255, 1)",
      borderColor: "rgba(0, 123, 255, 1)",
      pointBackgroundColor: "rgba(0, 123, 255, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(0, 123, 255, 1)"
    }
  ];

  /* Gráfico de Venda Mensal */

  lineChartOptionsVendas: ChartOptions = {
    animation: {
      duration: 0
    },
    responsive: true,
    legend: {
      labels: {
        fontColor: "rgba(0, 0, 0, 1)"
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: "rgba(0, 0, 0, 1)"
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        align: "top",
        anchor: "center",
        font: {
          weight: "bold"
        }
      }
    }
  };

  lineChartLabelsVendas: Label[] = [];
  lineChartTypeVendas: ChartType = "line";
  lineChartLegendVendas = true;
  lineChartPluginsVendas = [pluginDataLabels];

  lineChartDataVendas: ChartDataSets[] = [
    { data: [], label: "" },
    { data: [], label: "" }
  ];

  lineChartColorsVendas: Array<any> = [
    {
      backgroundColor: "rgba(40, 167, 69, 0.2)",
      borderColor: "rgba(40, 167, 69, 1)",
      pointBackgroundColor: "rgba(40, 167, 69, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(40, 167, 69, 1)"
    },
    {
      backgroundColor: "rgba(0, 123, 255, 0.2)",
      borderColor: "rgba(0, 123, 255, 1)",
      pointBackgroundColor: "rgba(0, 123, 255, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(0, 123, 255, 0.8)"
    }
  ];

  /* Histórico dos Últimos Pedidos / Orçamentos */

  ultimaCompra: Date = null;
  qtdeDiasSemComprar: number = 0;
  valorTicketMedio: number = 0;

  /* Histórico de Ligações */

  ultimaVisita: Date = null;
  qtdeDiasSemVisita: number = 0;
  resultadoComMaiorFrequencia: string = "";

  /* Itens (Menu) */

  itemSelecionado: number = 0;

  /* Ligação - Finalizar */

  mensagemLigacao: string = "";
  observacaoVisitaAtual: string = "";

  agendarLigacaoData: { year: number, month: number, day: number } = null;
  agendarLigacaoDataInvalida: boolean = false;
  agendarLigacaoHora: { hour: number, minute: number } = null;

  ligacaoHoraAtual: { hour: number, minute: number } = null;

  /* MultiSelect */

  multiSelectConfiguracoes: IMultiSelectSettings = {
    buttonClasses: "btn btn-light btn-block"
  };

  multiSelectConfiguracoesTextos: IMultiSelectTexts = {
    defaultTitle: "Selecionar resposta(s)..."
  };

  /* Notas Fiscais */

  notasFiscais: NotaFiscalSintetica[] = [];

  /* Pedidos (Cliente) */

  pedidosClientes: PedidoSintetico[] = [];
  pedidosEnviadosClientes: PedidoSintetico[] = [];
  pedidosNaoEnviadosClientes: PedidoSintetico[] = [];

  pedidoNaoEnviadoClienteHoje: PedidoSintetico = null;

  /* Pedidos (FatorFinanceiro) */

  exibirFatorFinanceiroEnviados: boolean = false;
  exibirFatorFinanceiroNaoEnviados: boolean = false;

  /* Pedidos Itens (Cliente) */

  pedidosItensClientes: PedidoItensSintetico[] = [];
  pedidosItensEnviadosClientes: PedidoItensSintetico[] = [];
  pedidosItensNaoEnviadosClientes: PedidoItensSintetico[] = [];

  /* Pedidos Itens Enviados (Cliente) */

  pedidosItensEnviados: PedidoItensSintetico[] = [];

  pedidosItensEnviadosExpandido: number = -1;

  /* Pedidos Itens Não Enviados (Cliente) */

  pedidosItensNaoEnviados: PedidoItensSintetico[] = [];

  pedidosItensNaoEnviadosExpandido: number = -1;

  /* Redirecionar */

  redirecionarPorTempo: boolean = false;

  /* Modal */

  @ViewChild("novaLigacao", { static: false }) novaLigacao: ElementRef;

  /* Storage */

  is_master: boolean = (localStorage.getItem("is_master") == "true");
  is_manager: boolean = (localStorage.getItem("is_manager") == "true");
  is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");
  modelo_tela_ligacoes: number = parseInt(localStorage.getItem("modelo_tela_ligacoes"));
  seller_id: number = parseInt(localStorage.getItem("id"));
  usa_pabx: boolean = (localStorage.getItem("usa_pabx") == "true");
  usa_whatsapp: boolean = (localStorage.getItem("usa_whatsapp") == "true");
  token_innersac: string = localStorage.getItem("token_innersac");
  link_innersac: string = localStorage.getItem("link_innersac");

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private automacaoEventoService: AutomacaoEventoService,
    private callCenterService: CallCenterService,
    private callCenterDiscadorService: CallCenterDiscadorService,
    private canaisComunicacaoTelefoniaService: CanaisComunicacaoTelefoniaService,
    private centralAtendimentoService: CentralAtendimentoService,
    private clienteService: ClienteService,
    private configuracaoService: ConfiguracaoService,
    private conversaoService: ConversaoService,
    private excelService: ExcelService,
    private formatacaoService: FormatacaoService,
    private notaFiscalService: NotaFiscalService,
    private pedidoService: PedidoService,
    private pedidoItensService: PedidoItensService,
    private produtoService: ProdutoService,
    private tempoProgressivoService: TempoProgressivoService,
    private tempoRegressivoService: TempoRegressivoService,
    private vendedorService: VendedorService,
    private visitaService: VisitaService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();

    if (this.router.routerState.snapshot.url.indexOf("pedidos-cadastro") !== 1) {
      /* Em Atendimento */

      this.atualizarEmAtendimento(false);

      /* Tempo Progressivo (Encerrar) */

      this.tempoProgressivoService.encerrarTempo();

      /* Storage */

      localStorage.setItem("id_call_center_ligacao", "0");
    }

    /* Tempo Regressivo (Encerrar) */

    this.tempoRegressivoService.encerrarTempo();

    /* Emitter */

    this.eventosEmitter.unsubscribe();
    this.redirecionarPorTempoEmitter.unsubscribe();
  }

  ngOnInit() {
    /* Emmiter */

    this.receberEventosTelefonia();
    this.receberRedirecionar();

    /* Externo */

      /* Cliente Consulta (Externo) */

      let idClienteConsulta: string = null;

      this.route.queryParams.subscribe(parametros => { idClienteConsulta = parametros["cliente_consulta"] });

      if (idClienteConsulta != null && !isNaN(parseInt(idClienteConsulta)) && isFinite(parseInt(idClienteConsulta))) {
        this.clienteConsultaSelecionadoExterno = parseInt(idClienteConsulta);
      }

      /* Pedido (Externo) */

      let idPedido: string = null;

      this.route.queryParams.subscribe(parametros => { idPedido = parametros["pedido"] });

      if (idPedido != null && !isNaN(parseInt(idPedido)) && isFinite(parseInt(idPedido))) {
        this.pedidoSelecionadoExterno = parseInt(idPedido);
      }

      /* Vendedor (Externo) */

      let idVendedor: string = null;

      this.route.queryParams.subscribe(parametros => { idVendedor = parametros["vendedor"] });

      if (idVendedor != null && !isNaN(parseInt(idVendedor)) && isFinite(parseInt(idVendedor))) {
        this.vendedorSelecionadoExterno = parseInt(idVendedor);
      }

      /* Visita (Externa) */

      let idVisita: string = null;

      this.route.queryParams.subscribe(parametros => { idVisita = parametros["visita"] });

      if (idVisita != null && !isNaN(parseInt(idVisita)) && isFinite(parseInt(idVisita))) {
        this.visitaSelecionadaExterna = parseInt(idVisita);
      }

    /* Formulário (Qualificação (Questões)) */

    this.formQualificacaoQuestoes = new FormGroup({});

    this.carregarDados();
  }

  /* Call Center (Agendamentos) */

  deletarAgendamento(id: number) {
    let callCenterAgendamento = this.callCenterAgendamentos.findIndex(agendamento => agendamento.id == id);

    if (callCenterAgendamento != -1) {
      let deletarAgendamento: boolean = false;

      this.callCenterService.deleteAgendamentoById(id).subscribe(
        deletarAgendamentos => deletarAgendamento = deletarAgendamentos,
        error => { console.log("Erro: " + error) },
        () => {
          if (deletarAgendamento) {
            this.callCenterAgendamentos.splice(callCenterAgendamento, 1);

            this.callCenterAgendamentos = [...this.callCenterAgendamentos];

            this.toastr.success("", "Agendamento excluído com sucesso!");
          }
        }
      );
    }
  }

  /* Call Center (Resultados) */

  selecionarResultado(id_resultado: string) {
    this.resultadoSelecionado = this.resultados.find(resultado => resultado.id == parseInt(id_resultado));
  }

  /* Call Center (Status) */

  selecionarStatus(id_status: string) {
    this.statusSelecionado = this.status.find(status => status.id == parseInt(id_status));
  }

  /* Central de Atendimento */

  iniciarChamada(numero_telefone: number) {
    let numero: string = numero_telefone.toString().trim();

    if (numero == "") {
      this.toastr.error("", "Telefone em branco!");
    } else if (numero.length < 8) {
      this.toastr.error("", "Telefone inválido!");
    } else {
      this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("telefonia", "iniciar_chamada", "", "", 0, false, false, false, false, false, "", "", numero, null, "", 0));
    }
  }

  iniciarChat(dddWhatsApp: number, telefoneWhatsApp: number) {
    if (dddWhatsApp.toString().trim() == "") {
      this.toastr.error("", "WhatsApp (DDD) em branco!");
    } else if (telefoneWhatsApp.toString().trim() == "") {
      this.toastr.error("", "WhatsApp (Telefone) em branco!");
    } else {
      if (this.token_innersac != "" && this.link_innersac != "") {
        this.router.navigate(["/whatsapp-innersac/" + dddWhatsApp.toString().trim() + telefoneWhatsApp.toString().trim()]);
      }

      /*
      let numero: string = this.conversaoService.telefoneParaTelefoneWhatsApp(dddWhatsApp.toString().trim() + telefoneWhatsApp.toString().trim());

      this.centralAtendimentoService.adicionarEvento(new CentralAtendimentoEvento("whatsapp", "iniciar_chat", "", "", 0, false, true, false, false, false, this.cliente.name, "", numero, null, "", this.cliente.client_id_app));
      */
    }
  }

  /* Dados */

  atualizarEmAtendimento(em_atendimento: boolean) {
    let atualizarVendedor: boolean = false;

    this.vendedorService.updateEmAtendimentoByVendedor(em_atendimento).subscribe(
      atualizarVendedores => atualizarVendedor = atualizarVendedores,
      error => { console.log("Erro: " + error) },
      () => {

      }
    );
  }

  calcularDadosPedidos() {
    /* Gráfico de Venda por ? */

    /* Por Marca */

    let pedidosItensClientesAgrupados = this.pedidosItensEnviadosClientes.reduce((obj, item) => {
      obj[item.grupo_estoque] = obj[item.grupo_estoque] || [];
      obj[item.grupo_estoque].push(item);

      return obj;
    }, {});

    pedidosItensClientesAgrupados = Object.keys(pedidosItensClientesAgrupados).map((objeto) => {
      return {
        key: objeto,
        value: pedidosItensClientesAgrupados[objeto]
      };
    }).sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a.value.length, b.value.length) * -1; /* Decrescente */
    });

    this.barChartLabelsProdutosPorMarca = [];
    this.barChartDataProdutosPorMarca = [];

    for (var i = 0; i < Object.keys(pedidosItensClientesAgrupados).length; i++) {
      this.barChartLabelsProdutosPorMarca.push(pedidosItensClientesAgrupados[i].key);
      this.barChartDataProdutosPorMarca.push(pedidosItensClientesAgrupados[i].value.length);
    }

    /* Por Produto */

    pedidosItensClientesAgrupados = this.pedidosItensEnviadosClientes.reduce((obj, item) => {
      obj[item.produto] = obj[item.produto] || [];
      obj[item.produto].push(item);

      return obj;
    }, {});

    pedidosItensClientesAgrupados = Object.keys(pedidosItensClientesAgrupados).map((objeto) => {
      return {
        key: objeto,
        value: pedidosItensClientesAgrupados[objeto]
      };
    }).sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a.value.length, b.value.length) * -1; /* Decrescente */
    });

    this.barChartLabelsProdutosPorProduto = [];
    this.barChartDataProdutosPorProduto = [];

    for (var i = 0; i < Object.keys(pedidosItensClientesAgrupados).length; i++) {
      if (i < 6) {
        this.barChartLabelsProdutosPorProduto.push(pedidosItensClientesAgrupados[i].key.substring(0, 20));
        this.barChartDataProdutosPorProduto.push(pedidosItensClientesAgrupados[i].value.length);
      }
    }

    this.carregarDadosGraficoProdutoSelecionado();

    /* Gráfico de Venda Mensal */

    let dataInicial = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let dataFinal = new Date();

    let dataTemporaria = new Date();

    /* Este Ano */

    this.lineChartDataVendas[0].label = dataInicial.getFullYear().toString();

    this.lineChartLabelsVendas.push(this.conversaoService.numeroParaMesPorExtenso(dataInicial.getMonth()));

    let pedidosItensClientesPorPeriodo = this.pedidosItensEnviadosClientes.filter(pedidoItensClientePorPeriodo => {
      let data = this.datePipe.transform(pedidoItensClientePorPeriodo.order_sent_on, "yyyy/MM/dd");

      return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
    });

    this.lineChartDataVendas[0].data.push(this.formatacaoService.duasCasasDecimais(pedidosItensClientesPorPeriodo.reduce((sum, current) => sum + current.total, 0)));

    for (var i = 0; i < 5; i++) {
      dataTemporaria = new Date(dataInicial.getFullYear(), dataInicial.getMonth(), 1);

      dataInicial = new Date(dataTemporaria.setMonth(dataTemporaria.getMonth() - 1));
      dataFinal = new Date(dataFinal.getFullYear(), dataFinal.getMonth(), 0);

      this.lineChartLabelsVendas.push(this.conversaoService.numeroParaMesPorExtenso(dataInicial.getMonth()));

      pedidosItensClientesPorPeriodo = this.pedidosItensEnviadosClientes.filter(pedidoItensClientePorPeriodo => {
        let data = this.datePipe.transform(pedidoItensClientePorPeriodo.order_sent_on, "yyyy/MM/dd");

        return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
      });

      this.lineChartDataVendas[0].data.push(this.formatacaoService.duasCasasDecimais(pedidosItensClientesPorPeriodo.reduce((sum, current) => sum + current.total, 0)));
    }

    this.lineChartDataVendas[0].data.reverse();

    /* Ano Passado */

    dataInicial = new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1);
    dataFinal = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate());

    this.lineChartDataVendas[1].label = dataInicial.getFullYear().toString();

    pedidosItensClientesPorPeriodo = this.pedidosItensEnviadosClientes.filter(pedidoItensClientePorPeriodo => {
      let data = this.datePipe.transform(pedidoItensClientePorPeriodo.order_sent_on, "yyyy/MM/dd");

      return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
    });

    this.lineChartDataVendas[1].data.push(this.formatacaoService.duasCasasDecimais(pedidosItensClientesPorPeriodo.reduce((sum, current) => sum + current.total, 0)));

    for (var i = 0; i < 5; i++) {
      dataTemporaria = new Date(dataInicial.getFullYear(), dataInicial.getMonth(), 1);

      dataInicial = new Date(dataTemporaria.setMonth(dataTemporaria.getMonth() - 1));
      dataFinal = new Date(dataFinal.getFullYear(), dataFinal.getMonth(), 0);

      pedidosItensClientesPorPeriodo = this.pedidosItensEnviadosClientes.filter(pedidoItensClientePorPeriodo => {
        let data = this.datePipe.transform(pedidoItensClientePorPeriodo.order_sent_on, "yyyy/MM/dd");

        return data >= this.datePipe.transform(dataInicial, "yyyy/MM/dd") && data <= this.datePipe.transform(dataFinal, "yyyy/MM/dd");
      });

      this.lineChartDataVendas[1].data.push(this.formatacaoService.duasCasasDecimais(pedidosItensClientesPorPeriodo.reduce((sum, current) => sum + current.total, 0)));
    }

    this.lineChartDataVendas[1].data.reverse();

    this.lineChartLabelsVendas.reverse();

    /* Acompanhamento (Gráfico de Venda Mensal) */

    pedidosItensClientesAgrupados = this.pedidosItensEnviadosClientes.reduce((obj, item) => {
      let data = new Date(this.datePipe.transform(item.order_sent_on, "yyyy/MM/dd HH:mm:ss"));

      obj[(data.getMonth() + 1) + "/" + data.getFullYear()] = obj[(data.getMonth() + 1) + "/" + data.getFullYear()] || [];
      obj[(data.getMonth() + 1) + "/" + data.getFullYear()].push(item);

      return obj;
    }, {});

    Object.keys(pedidosItensClientesAgrupados).forEach((item) => {
      let valor = pedidosItensClientesAgrupados[item].reduce((sum, current) => sum + current.total, 0)

      if (valor > this.valorMesComMelhorVenda) {
        this.mesComMelhorVenda = item;
        this.valorMesComMelhorVenda = valor;
      }
    });

    let pedidosClientesAgrupados = this.pedidosEnviadosClientes.reduce((obj, item) => {
      let data = new Date(this.datePipe.transform(item.order_sent_on, "yyyy/MM/dd HH:mm:ss"));

      obj[(data.getMonth() + 1) + "/" + data.getFullYear()] = obj[(data.getMonth() + 1) + "/" + data.getFullYear()] || [];
      obj[(data.getMonth() + 1) + "/" + data.getFullYear()].push(item);

      return obj;
    }, {});

    if (this.pedidosEnviadosClientes.length > 0 && Object.keys(pedidosClientesAgrupados).length > 0) {
      this.qtdeMediaMensal = parseInt((this.pedidosEnviadosClientes.length / Object.keys(pedidosClientesAgrupados).length).toFixed(0));
    }

    /* Comparativo de Vendas por Produto (6 Meses) */

    pedidosItensClientesAgrupados = this.pedidosItensEnviadosClientes.reduce((obj, item) => {
      obj[item.produto] = obj[item.produto] || [];
      obj[item.produto].push(item);

      return obj;
    }, {});

    pedidosItensClientesAgrupados = Object.keys(pedidosItensClientesAgrupados).map((objeto) => {
      return {
        key: objeto,
        value: pedidosItensClientesAgrupados[objeto]
      };
    });

    this.comparativoAnoPassadoDtInicial = new Date(new Date().getFullYear() - 1, new Date().getMonth() - 5, 1);
    this.comparativoAnoPassadoAnoDtFinal = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate());

    this.comparativoEsteAnoDtInicial = new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1);
    this.comparativoEsteAnoDtFinal = new Date();

    for (var i = 0; i < Object.keys(pedidosItensClientesAgrupados).length; i++) {
      let valorAnoPassado = this.pedidosItensEnviadosClientes.filter(pedidoItensClientes => {
        let data = new Date(this.datePipe.transform(pedidoItensClientes.order_sent_on, "yyyy/MM/dd HH:mm:ss"));

        return data >= this.comparativoAnoPassadoDtInicial && data <= this.comparativoAnoPassadoAnoDtFinal && pedidoItensClientes.produto == pedidosItensClientesAgrupados[i].key;
      }).reduce((sum, current) => sum + current.total, 0);

      let litragemAnoPassado = this.pedidosItensEnviadosClientes.filter(pedidoItensClientes => {
        let data = new Date(this.datePipe.transform(pedidoItensClientes.order_sent_on, "yyyy/MM/dd HH:mm:ss"));

        return data >= this.comparativoAnoPassadoDtInicial && data <= this.comparativoAnoPassadoAnoDtFinal && pedidoItensClientes.produto == pedidosItensClientesAgrupados[i].key;
      }).reduce((sum, current) => sum + current.total_litragem, 0);

      let valorEsteAno = this.pedidosItensEnviadosClientes.filter(pedidoItensClientes => {
        let data = new Date(this.datePipe.transform(pedidoItensClientes.order_sent_on, "yyyy/MM/dd HH:mm:ss"));

        return data >= this.comparativoEsteAnoDtInicial && data <= this.comparativoEsteAnoDtFinal && pedidoItensClientes.produto == pedidosItensClientesAgrupados[i].key;
      }).reduce((sum, current) => sum + current.total, 0);

      let litragemEsteAno = this.pedidosItensEnviadosClientes.filter(pedidoItensClientes => {
        let data = new Date(this.datePipe.transform(pedidoItensClientes.order_sent_on, "yyyy/MM/dd HH:mm:ss"));

        return data >= this.comparativoEsteAnoDtInicial && data <= this.comparativoEsteAnoDtFinal && pedidoItensClientes.produto == pedidosItensClientesAgrupados[i].key;
      }).reduce((sum, current) => sum + current.total_litragem, 0);

      let ganhoPerdaValor = valorEsteAno - valorAnoPassado;
      let ganhoPerdaLitragem = litragemEsteAno - litragemAnoPassado;

      if (valorAnoPassado > 0 || valorEsteAno > 0) {
        this.callCenterComparativoVendasProdutos.push(new CallCenterComparativoVendaProduto(pedidosItensClientesAgrupados[i].key, valorAnoPassado, valorEsteAno, litragemAnoPassado, litragemEsteAno, ganhoPerdaValor, ganhoPerdaLitragem));
      }
    }

    this.callCenterComparativoVendasProdutos = this.callCenterComparativoVendasProdutos.sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a["ganho_perda"], b["ganho_perda"]) * -1; /* Decrescente */
    });

    /* Litragem */

    this.comparativoAnoPassadoTotalGanhoPerdaLitragem = this.callCenterComparativoVendasProdutos.reduce((sum, current) => sum + current.litragem_ano_passado, 0);
    this.comparativoEsteAnoTotalGanhoPerdaLitragem = this.callCenterComparativoVendasProdutos.reduce((sum, current) => sum + current.litragem_este_ano, 0);
    this.totalComparativoGanhoPerdaLitragem = this.comparativoEsteAnoTotalGanhoPerdaLitragem - this.comparativoAnoPassadoTotalGanhoPerdaLitragem;

    /* Valor */

    this.comparativoAnoPassadoTotalGanhoPerdaValor = this.callCenterComparativoVendasProdutos.reduce((sum, current) => sum + current.valor_ano_passado, 0);
    this.comparativoEsteAnoTotalGanhoPerdaValor = this.callCenterComparativoVendasProdutos.reduce((sum, current) => sum + current.valor_este_ano, 0);
    this.totalComparativoGanhoPerdaValor = this.comparativoEsteAnoTotalGanhoPerdaValor - this.comparativoAnoPassadoTotalGanhoPerdaValor;

    /* Histórico dos Últimos Pedidos / Orçamentos */

    if (this.pedidosEnviadosClientes.length > 0) {
      this.ultimaCompra = new Date(this.pedidosEnviadosClientes[0].order_sent_on);
      this.qtdeDiasSemComprar = parseInt(this.conversaoService.milissegundosParaDias(new Date().getTime() - this.ultimaCompra.getTime()).toFixed(0));

      let valorVendasTotal = this.pedidosEnviadosClientes.reduce((sum, current) => sum + current.total, 0);

      if (valorVendasTotal > 0) {
        this.valorTicketMedio = valorVendasTotal / this.pedidosEnviadosClientes.length;
      }
    }
  }

  calcularDadosVisitas() {
    if (this.clienteConsultaSelecionadoExterno != 0) {
      this.ultimaVisita = new Date(this.visitas[0].check_in);
    } else {
      let data = this.datePipe.transform(this.visitas[0].check_in, "yyyy/MM/dd");

      if (data == this.datePipe.transform(new Date(), "yyyy/MM/dd")) {
        this.visitas.splice(0, 1);

        this.visitas = [...this.visitas];

        if (this.visitas.length > 0) {
          this.ultimaVisita = new Date(this.visitas[0].check_in);
        }
      } else {
        this.ultimaVisita = new Date(this.visitas[0].check_in);
      }
    }

    if (this.ultimaVisita != null) {
      this.qtdeDiasSemVisita = parseInt(this.conversaoService.milissegundosParaDias(new Date().getTime() - this.ultimaVisita.getTime()).toFixed(0));
    }

    let visitasAgrupadas = this.visitas.filter(visita => visita.name != null).reduce((obj, item) => {
      obj[item.name] = obj[item.name] || [];
      obj[item.name].push(item);

      return obj;
    }, {});

    visitasAgrupadas = Object.keys(visitasAgrupadas).map((objeto) => {
      return {
        key: objeto,
        value: visitasAgrupadas[objeto]
      };
    }).sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a.value.length, b.value.length) * -1; /* Decrescente */
    });

    this.resultadoComMaiorFrequencia = Object.keys(visitasAgrupadas).length > 0 ? visitasAgrupadas[0].key : "";
  }

  carregarDados() {
    this.carregando = true;

    /* Configurações */

    this.configuracaoService.getByGuidClienteNapis().subscribe(
      configuracoes => this.configuracao = configuracoes,
      error => { console.log("Erro: " + error) },
      () => {
        /* Call Center (Configurações) */

        this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
          callCenterConfiguracao => this.callCenterConfiguracao = callCenterConfiguracao,
          error => { console.log("Erro: " + error) },
          () => {
            /* Call Center (Resultados) */

            this.callCenterService.getResultadoByGuidClienteNapis().subscribe(
              resultados => this.resultados = resultados,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.resultados.length > 0) {
                  this.resultadoSelecionado = this.resultados[0];
                }

                /* Call Center (Status) */

                this.callCenterService.getStatus().subscribe(
                  status => this.status = status,
                  error => { console.log("Erro: " + error) },
                  () => {
                    if (this.status.length > 0) {
                      this.statusSelecionado = this.status.find(status => status.id == 5); /* Não Comprou */
                    }

                    this.carregarLigacao();
                  }
                );
              }
            );
          }
        );
      }
    );
  }

  carregarDadosCesta() {
    if (!this.carregadoCesta) {
      /* Produtos (Cesta) */

      this.produtoService.getProdutoCestaByGuidClienteNapis(this.cliente.segment).subscribe(
        produtoCesta => this.produtosCesta = produtoCesta,
        error => { console.log("Erro: " + error) },
        () => {

        }
      );
    }
  }

  carregarDadosCliente(client_id_app: number) {
    let retorno = new Promise<void>((resolver) => {
      /* Cliente */

      this.clienteService.getById(client_id_app).subscribe(
        clientes => this.cliente = clientes,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.cliente != null) {
            this.cliente.name = this.cliente.name.trim().toUpperCase();
            this.cliente.endereco_completo = "";

            if (this.cliente.tipo_logradouro != null) {
              this.cliente.endereco_completo = this.cliente.endereco_completo + this.cliente.tipo_logradouro.trim();
            }

            if (this.cliente.endereco != null) {
              this.cliente.endereco_completo = this.cliente.endereco_completo + " " + this.cliente.endereco.trim();
            }

            if (this.cliente.end_numero != null) {
              this.cliente.endereco_completo = this.cliente.endereco_completo + ", " + this.cliente.end_numero.trim();
            }

            if (this.cliente.cidade != null) {
              this.cliente.endereco_completo = this.cliente.endereco_completo + " / " + this.cliente.cidade.trim();
            }

            this.cliente.endereco_completo = this.cliente.endereco_completo.trim().toUpperCase();

            this.insideSales = this.cliente.inside_sales_ativo == null ? false : this.cliente.inside_sales_ativo;
            this.contatoCliente = this.cliente.contato_callcenter == null ? "" : this.cliente.contato_callcenter;
            this.telefone_2 = this.cliente.fone_02 == null ? "" : this.cliente.fone_02;
            this.telefone_3 = this.cliente.fone_03 == null ? "" : this.cliente.fone_03;
            this.dddWhatsApp = this.cliente.fone_whatss_ddd == null ? "" : this.cliente.fone_whatss_ddd;
            this.telefoneWhatsApp = this.cliente.fone_whatss == null ? "" : this.cliente.fone_whatss;
            this.email_02 = this.cliente.email == null ? "" : this.cliente.email;
            this.observacaoCliente = this.cliente.note == null ? "" : this.cliente.note;

            /* Call Center (Agendamentos) */

            this.callCenterService.getAgendamentoByCliente(this.cliente.client_id_app).subscribe(
              callCenterAgendamento => this.callCenterAgendamentos = callCenterAgendamento,
              error => { console.log("Erro: " + error) },
              () => {
                this.carregando = false;

                return resolver();
              }
            )
          }
        }
      );
    });

    return retorno;
  }

  carregarDadosPedidos() {
    if (!this.carregadoPedidos) {
      /* Cliente (Tabela) */

      this.clienteService.getTabelaByIdCliente(this.cliente.client_id_app).subscribe(
        tabelaCliente => this.tabelasClientes = tabelaCliente,
        error => { console.log("Erro: " + error) },
        () => {
          /* Pedidos */

          this.pedidoService.getByCliente(this.cliente.client_id_app).subscribe(
            pedidoCliente => this.pedidosClientes = pedidoCliente,
            error => { console.log("Erro: " + error) },
            () => {
              let pedidosEnviadosClientes = this.pedidosClientes.filter(pedidoCliente => pedidoCliente.order_sent_on != null && pedidoCliente.dt_integracao != null);

              this.pedidosEnviadosClientes = JSON.parse(JSON.stringify(pedidosEnviadosClientes));

              this.pedidosEnviadosClientes = this.pedidosEnviadosClientes.sort((a, b) => {
                const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

                let dataAnterior = this.datePipe.transform(a["order_sent_on"], "yyyy/MM/dd");
                let proximaData = this.datePipe.transform(b["order_sent_on"], "yyyy/MM/dd");

                return compare(dataAnterior, proximaData) * -1; /* Decrescente */
              });

              let pedidosNaoEnviadosClientes = this.pedidosClientes.filter(pedidoCliente => pedidoCliente.order_sent_on == null);

              this.pedidosNaoEnviadosClientes = JSON.parse(JSON.stringify(pedidosNaoEnviadosClientes));

              this.pedidosNaoEnviadosClientes.forEach((item) => {
                let data = this.datePipe.transform(item.order_created_at, "yyyy/MM/dd");

                if (data == this.datePipe.transform(new Date(), "yyyy/MM/dd")) {
                  this.pedidoNaoEnviadoClienteHoje = item;
                }
              });

              this.pedidosNaoEnviadosClientes = this.pedidosNaoEnviadosClientes.sort((a, b) => {
                const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

                let dataAnterior = this.datePipe.transform(a["order_created_at"], "yyyy/MM/dd");
                let proximaData = this.datePipe.transform(b["order_created_at"], "yyyy/MM/dd");

                return compare(dataAnterior, proximaData) * -1; /* Decrescente */
              });

              /* Pedidos Itens */

              this.pedidoItensService.getByCliente(this.cliente.client_id_app).subscribe(
                pedidoItensCliente => this.pedidosItensClientes = pedidoItensCliente,
                error => { console.log("Erro: " + error) },
                () => {
                  if (this.pedidosItensClientes.length > 0) {
                    let pedidosItensEnviadosClientes = this.pedidosItensClientes.filter(pedidoItemCliente => pedidoItemCliente.order_sent_on != null && pedidoItemCliente.dt_integracao != null);

                    this.pedidosItensEnviadosClientes = JSON.parse(JSON.stringify(pedidosItensEnviadosClientes));

                    let pedidosItensNaoEnviadosClientes = this.pedidosItensClientes.filter(pedidoItemCliente => pedidoItemCliente.order_sent_on == null);

                    this.pedidosItensNaoEnviadosClientes = JSON.parse(JSON.stringify(pedidosItensNaoEnviadosClientes));

                    /* Grupos Estoque */

                    this.produtoService.getGrupoEstoqueByGuidClienteNapis().subscribe(
                      grupoEstoque => this.gruposEstoque = grupoEstoque,
                      error => { console.log("Erro: " + error) },
                      () => {
                        for (var i = 0; i < this.gruposEstoque.length; i++) {
                          let qtdeGrupoEstoque = this.pedidosItensEnviadosClientes.filter(pedidoItensClientes => pedidoItensClientes.grupo_estoque == this.gruposEstoque[i]).length;

                          if (qtdeGrupoEstoque > 0) {
                            this.gruposEstoque.splice(i, 1);

                            this.gruposEstoque = [...this.gruposEstoque];
                          }
                        }

                        this.calcularDadosPedidos();

                        this.carregadoPedidos = true;
                      }
                    );
                  }
                }
              );
            }
          );
        }
      );
    }
  }

  carregarDadosQualificacao() {
    if (!this.carregadoQualificacao) {
      /* Qualificação (Questões) */

      this.callCenterService.getQualificacaoQuestaoByGuidClienteNapis().subscribe(
        callCenterQualificacaoQuestao => this.callCenterQualificacaoQuestoes = callCenterQualificacaoQuestao,
        error => { console.log("Erro: " + error) },
        () => {
          /* Formulário (Qualificação (Questões)) */

          this.callCenterQualificacaoQuestoes.forEach((item) => {
            if (item.tipo == "multipla_escolha") {
              this.formQualificacaoQuestoes.addControl(item.id.toString(), this.formBuilder.control([]));
            } else {
              this.formQualificacaoQuestoes.addControl(item.id.toString(), this.formBuilder.control(""));
            }

            if (item.flag_obrigatoria) {
              this.formQualificacaoQuestoes.controls[item.id].setValidators(Validators.required);
            }
          });

          /* Qualificação (Questões (Respostas)) */

          this.callCenterService.getCallCenterQualificacaoQuestaoResposta(this.ligacao.client_id).subscribe(
            callCenterQualificacaoQuestoesResposta => this.callCenterQualificacaoQuestoesRespostas = callCenterQualificacaoQuestoesResposta,
            error => { console.log("Erro: " + error) },
            () => {
              this.callCenterQualificacaoQuestoes.forEach((item) => {
                if (item.tipo == "escrita") {
                  /* Escrita */

                  let respostaLocalizada = this.callCenterQualificacaoQuestoesRespostas.find(resposta => resposta.id_call_center_qualificacao_questao == item.id);

                  if (respostaLocalizada != null) {
                    this.formQualificacaoQuestoes.controls[item.id].setValue(respostaLocalizada.resposta);
                  }
                } else if (item.tipo == "multipla_escolha") {
                  /* Múltipla Escolha */

                  let respostas: any = [];

                  this.callCenterQualificacaoQuestoesRespostas.filter(x => x.id_call_center_qualificacao_questao == item.id).forEach((item) => {
                    respostas.push(item.id_call_center_qualificacao_questao_alternativa);
                  });

                  this.formQualificacaoQuestoes.controls[item.id].setValue(respostas);
                } else if (item.tipo == "unica_alternativa") {
                  /* Única Alternativa */

                  let respostaLocalizada = this.callCenterQualificacaoQuestoesRespostas.find(resposta => resposta.id_call_center_qualificacao_questao == item.id);

                  if (respostaLocalizada != null) {
                    this.formQualificacaoQuestoes.controls[item.id].setValue(respostaLocalizada.id_call_center_qualificacao_questao_alternativa);
                  }
                }

                /* MultiSelect */

                if (item.tipo == "multipla_escolha") {
                  item.opcoes = [];

                  item.alternativas.forEach((subItem) => {
                    item.opcoes.push({
                      id: subItem.id,
                      name: subItem.descricao
                    })
                  });
                }
              });

              this.carregadoQualificacao = true;
            }
          );
        }
      );
    }
  }

  carregarDadosVisitas() {
    if (!this.carregadoVisitas) {
      /* Visitas */

      this.visitaService.getVisitaByCliente(this.cliente.client_id_app).subscribe(
        visitas => this.visitas = visitas,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.visitas.length > 0) {
            this.visitas.forEach((item) => {
              if (item.no_order_done_reason_id == null || item.no_order_done_reason_id == 0) {
                item.name = "Venda Realizada";
              }
            });

            this.calcularDadosVisitas();
          }

          this.carregadoVisitas = true;
        }
      );
    }
  }

  /* Emitter */

  receberEventosTelefonia() {
    this.eventosEmitter = this.canaisComunicacaoTelefoniaService.eventosEmitter.subscribe(
      canaisComunicacaoTelefoniaEventos => {
        if (this.callCenterConfiguracao != null) {
          if (this.callCenterConfiguracao.fechamento_automatico_ligacao) {
            if (canaisComunicacaoTelefoniaEventos != null) {
              switch (canaisComunicacaoTelefoniaEventos.acao) {
                case "chamada_encerrada":
                  let tempoAtendimento: number = this.callCenterConfiguracao.tempo_atendimento == null ? 0 : this.callCenterConfiguracao.tempo_atendimento;

                  this.tempoRegressivoService.iniciarTempo(tempoAtendimento);

                  break;
                case "chamada_em_andamento" || "discando" || "recebendo":
                  this.tempoRegressivoService.encerrarTempo();

                  break;
                default:

              }
            }
          }
        }
      }
    );
  }

  receberRedirecionar() {
    this.redirecionarPorTempoEmitter = this.tempoRegressivoService.redirecionarPorTempoEmitter.subscribe(
      redirecionarPorTempo => {
        if (redirecionarPorTempo) {
          this.redirecionarPorTempo = true;

          this.finalizarLigacaoAtual();
        }
      }
    );
  }
   
  /* Eventos */

  verificarAutomacaoEventos() {
    let automacaoEvento: AutomacaoEvento = null;

    this.automacaoEventoService.getByEvento("FINALIZAR LIGACAO").subscribe(
      automacaoEventos => automacaoEvento = automacaoEventos,
      error => { console.log("Erro: " + error) },
      () => {
        if (automacaoEvento != null) {
          let automacaoEnvio = new AutomacaoEnvio();

          automacaoEnvio.email = this.cliente.email;
          automacaoEnvio.phone = this.cliente.phone;
          automacaoEnvio.idvisit = this.visita.id;
          automacaoEnvio.dataligacao = this.datePipe.transform(new Date(), "yyyy-MM-dd");
          automacaoEnvio.razaosocial = this.cliente.name;
          automacaoEnvio.cidade = this.cliente.cidade;
          automacaoEnvio.sellerid = this.visita.seller_id;
          automacaoEnvio.vendedorexterno = this.visita.fullname != null ? this.visita.fullname : "";
          automacaoEnvio.emailvendedor = this.visita.email_real != null ? this.visita.email_real : "";
          automacaoEnvio.statusligacao = this.statusSelecionado.name;
          automacaoEnvio.resultadoligacao = this.resultadoSelecionado.name;
          automacaoEnvio.anotacoes = this.observacaoVisitaAtual;
          automacaoEnvio.segmento = this.cliente.segment;
          automacaoEnvio.fone_whatss = this.cliente.fone_whatss != null ? this.cliente.fone_whatss : "";
          automacaoEnvio.fone_whatss_ddd = this.cliente.fone_whatss_ddd != null ? this.cliente.fone_whatss_ddd : "";
          automacaoEnvio.responsible_name = this.cliente.responsible_name != null ? this.cliente.responsible_name : "";
          automacaoEnvio.contato_callcenter = this.cliente.contato_callcenter != null ? this.cliente.contato_callcenter : "";
          automacaoEnvio.client_id_app = this.cliente.client_id_app;

          let iniciarEvento: boolean = false;

          this.automacaoEventoService.iniciarEvento(automacaoEvento.link_evento, automacaoEnvio).subscribe(
            iniciarEventos => iniciarEvento = iniciarEventos,
            error => { console.log("Erro: " + error) },
            () => {
              if (iniciarEvento) {
                this.toastr.success("", "E-mail enviado com sucesso!");
              }
            }
          );
        }
      }
    );
  }

  /* Exportar */

  exportarComparativoVendas() {
    /* Comparativo de Vendas por Produto (6 Meses) */

    this.excelService.exportAsExcelFile(this.callCenterComparativoVendasProdutos, "Comparativo de Vendas por Produto (6 Meses)");
  }

  exportarHistoricoLigacoes() {
    /* Histórico de Ligações */

    this.excelService.exportAsExcelFile(this.visitas, "Histórico de Ligações");
  }

  exportarHistoricoUltimosPedidos() {
    /* Histórico dos Últimos Pedidos / Orçamentos */

    this.excelService.exportAsExcelFile(this.pedidosEnviadosClientes, "Histórico dos Últimos Pedidos / Orçamentos (Enviados)");
    this.excelService.exportAsExcelFile(this.pedidosNaoEnviadosClientes, "Histórico dos Últimos Pedidos / Orçamentos (Não Enviados)");
  }

  /* Formatação */

  formatarValoresQuatroCasasDecimais(valor: number) {
    if (valor != null) {
      return (valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
      });
    }
  }

  /* Ligação (Iniciar/Finalizar) */

    /* Cliente */

    updateCliente() {
      let alterarCliente: boolean = false;

      this.clienteService.updateContatoById(this.cliente.client_id_app, this.contatoCliente, this.email_02, this.observacaoCliente, this.telefone_2, this.telefone_3, this.dddWhatsApp, this.telefoneWhatsApp, this.insideSales).subscribe(
        alterarClientes => alterarCliente = alterarClientes,
        error => { console.log("Erro: " + error) },
        () => {
          if (alterarCliente) {
            this.toastr.success("", "Cliente atualizado com sucesso!");
          }
        }
      );
    }

    updateClienteWhatsApp() {
      let alterarWhatsApp: boolean = false;

      this.clienteService.updateWhatsAppById(this.cliente.client_id_app, this.dddWhatsApp, this.telefoneWhatsApp).subscribe(
        alterarWhatsApps => alterarWhatsApp = alterarWhatsApps,
        error => { console.log("Erro: " + error) },
        () => {

        }
      );
    }

    removerCaracteres(evento: KeyboardEvent) {
      if (evento.keyCode > 31 && (evento.keyCode < 48 || evento.keyCode > 57)) {
        evento.stopImmediatePropagation();

        return false;
      }

      return true;
    }

    removerCaracteresTelefone(evento: ClipboardEvent, telefone: number) {
      let elemento = evento.target as HTMLInputElement;
      let texto = evento.clipboardData.getData("text/plain");

      if (texto != null) {
        texto = texto.replace(/\D/g, "");

        elemento.value = texto;

        switch (telefone) {
          case 2:
            this.telefone_2 = texto;

            break;
          case 3:
            this.telefone_3 = texto;

            break;
          default:
        }

        evento.preventDefault();
      }
    }

    /* Ligação */

    carregarLigacao() {
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      }

      let id: string = null;

      this.route.params.subscribe(params => { id = params.id; });

      if (id != null && !isNaN(parseInt(id)) && isFinite(parseInt(id))) {
        const prepararReceberDadosLigacao = this.prepararReceberDadosLigacao(parseInt(id));

        prepararReceberDadosLigacao.then(() => {
          if (this.visitaSelecionadaExterna == 0) {
            this.addUpdateVisita(false);
          } else {
            const prepararReceberDadosVisita = this.prepararReceberDadosVisita(this.visitaSelecionadaExterna);

            prepararReceberDadosVisita.then(() => {
              if (this.pedidoSelecionadoExterno != 0) {
                this.addUpdateVisita(false);
                this.updateLigacaoAtual(false);
              }
            });
          }

          /* Em Atendimento */

          if (this.clienteConsultaSelecionadoExterno == 0) {
            this.atualizarEmAtendimento(true);
          }

          /* Tempo Progressivo (Iniciar) */

          if (this.pedidoSelecionadoExterno == 0) {
            this.tempoProgressivoService.iniciarTempo();
          }

          /* Tempo Regressivo (Iniciar) */

          if (this.callCenterConfiguracao != null) {
            if (this.callCenterConfiguracao.fechamento_automatico_ligacao) {
              let tempoAtendimento: number = this.callCenterConfiguracao.tempo_atendimento == null ? 0 : this.callCenterConfiguracao.tempo_atendimento;

              this.tempoRegressivoService.iniciarTempo(tempoAtendimento);
            }
          }
        });
      } else {
        if (this.clienteConsultaSelecionadoExterno != 0) {
          this.ligacao = new CallCenterLigacaoAnalitica();

          this.ligacao.manual = true;

          this.carregarDadosCliente(this.clienteConsultaSelecionadoExterno);
        } else {
          this.router.navigate(["/call-center"]);
        }
      }
    }

    prepararEnviarDadosLigacao(ligacao_futura: boolean) {
      /* Ligação */

      let ligacao = new CallCenterLigacaoAnalitica();

      ligacao.client_id = this.cliente.client_id_app;
      ligacao.seller_id = this.cliente.seller_id;
      ligacao.tmp = 1;
      ligacao.id_tmk = this.seller_id;
      ligacao.manual = this.ligacao.manual;

      if (ligacao_futura) {
        /* Futura */

        ligacao.tipo = "F"; /* Feita */
        ligacao.id_status = 6; /* Agendada */

        if (this.agendarLigacaoHora != null && this.agendarLigacaoHora.hour != null && this.agendarLigacaoHora.minute != null) {
          let dataLigacaoInicial = new Date(this.agendarLigacaoData.year, this.agendarLigacaoData.month - 1, this.agendarLigacaoData.day, this.agendarLigacaoHora.hour, this.agendarLigacaoHora.minute, 0);

          ligacao.data_ligacao = new Date(this.datePipe.transform(dataLigacaoInicial, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
        } else {
          let dataLigacaoInicial = new Date(this.agendarLigacaoData.year, this.agendarLigacaoData.month - 1, this.agendarLigacaoData.day, 0, 0, 0);

          ligacao.data_ligacao = new Date(this.datePipe.transform(dataLigacaoInicial, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
        }

        ligacao.manual = null;

        this.ligacaoFutura = ligacao;
      } else {
        /* Atual */

        ligacao.id = this.ligacao.id;
        ligacao.tipo = this.ligacao.tipo;

        if (this.redirecionarPorTempo) {
          ligacao.id_status = this.status.find(status => status.id == 5).id; /* Não Comprou */
        } else {
          ligacao.id_status = this.statusSelecionado.id_status;
        }

        if (this.ligacaoHoraAtual != null && this.ligacaoHoraAtual.hour != null && this.ligacaoHoraAtual.minute != null) {
          let dataLigacao = new Date();

          dataLigacao.setHours(this.ligacaoHoraAtual.hour);
          dataLigacao.setMinutes(this.ligacaoHoraAtual.minute);
          dataLigacao.setSeconds(0);

          ligacao.data_ligacao = new Date(this.datePipe.transform(dataLigacao, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
        } else {
          ligacao.data_ligacao = new Date(this.datePipe.transform(Date.now(), "yyyy/MM/dd HH:mm:ss", "GMT-6"));
        }

        ligacao.mensagem = this.mensagemLigacao;

        this.ligacao = ligacao;
      }
    }

    prepararReceberDadosLigacao(id_ligacao: number) {
      let retorno = new Promise<void>((resolver) => {
        /* Ligação */

        this.callCenterService.getById(id_ligacao).subscribe(
          ligacao => this.ligacao = ligacao,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.ligacao != null) {
              this.mensagemLigacao = this.ligacao.mensagem;

              if (this.pedidoSelecionadoExterno == 0) {
                /* Status */

                if (this.ligacao.id_status == 1 || this.ligacao.id_status == 6) { /* 1 = À Ligar / 6 = Agendada */
                  this.statusSelecionado = this.status.find(status => status.id == 5); /* Não Comprou */
                } else {
                  this.statusSelecionado = this.status.find(status => status.id == this.ligacao.id_status);
                }
              } else {
                /* Pedido */

                let pedido: Pedido = null;

                this.pedidoService.getByOrder(this.pedidoSelecionadoExterno, this.vendedorSelecionadoExterno).subscribe(
                  pedidos => pedido = pedidos,
                  error => { console.log("Erro: " + error) },
                  () => {
                    if (pedido != null && pedido.order_sent_on != null) {
                      this.statusSelecionado = this.status.find(status => status.id == 4); /* Venda Realizada */

                      this.pedidoSelecionadoExternoComVendaRealizada = true;

                      this.itemSelecionado = 6; /* Concluir Ligação */
                    } else {
                      this.statusSelecionado = this.status.find(status => status.id == this.ligacao.id_status);
                    }
                  }
                );
              }

              /* Storage */

              localStorage.setItem("id_call_center_ligacao", this.ligacao.id.toString());

              const carregarDadosCliente = this.carregarDadosCliente(this.ligacao.client_id);

              carregarDadosCliente.then(() => {
                return resolver();
              });
            }
          }
        );
      });

      return retorno;
    }

    /* Ligação Atual */

    updateLigacaoAtual(finalizando: boolean) {
      if (this.ligacao != null) {
        this.prepararEnviarDadosLigacao(false);

        let alterarLigacao: boolean = false;

        this.callCenterService.updateLigacao(this.ligacao).subscribe(
          alterarLigacoes => alterarLigacao = alterarLigacoes,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarLigacao) {
              if (finalizando) {
                this.toastr.success("", "Ligação salva com sucesso!");
              }
            }
          }
        );
      }
    }

    finalizarLigacaoAtual() {
      this.agendarLigacaoDataInvalida = false;

      if (this.agendarLigacaoData != null) {
        let data = new Date(this.agendarLigacaoData.year, this.agendarLigacaoData.month - 1, this.agendarLigacaoData.day);

        if (data < new Date()) {
          this.agendarLigacaoDataInvalida = true;
        } else {
          this.addLigacaoFutura();
        }
      }

      if (!this.agendarLigacaoDataInvalida) {
        this.addUpdateVisita(true);
        this.updateLigacaoAtual(true);
        this.updateCliente();

        this.router.navigate(["/call-center"]);

        this.callCenterDiscadorService.chamadasReiniciadas();
      }
    }

    /* Ligação Futura */

    addLigacaoFutura() {
      this.prepararEnviarDadosLigacao(true);

      let adicionarLigacao: boolean = false;

      this.callCenterService.addLigacao(this.ligacaoFutura).subscribe(
        adicionarLigacoes => adicionarLigacao = adicionarLigacoes,
        error => { console.log("Erro: " + error) },
        () => {
          if (adicionarLigacao) {
            this.toastr.success("", "Ligação agendada com sucesso!");
          }
        }
      );
    }

    /* Qualificação Cliente */

    addQualificacaoQuestoes() {
      this.qualificacaoQuestoesEnviado = true;

      if (!this.formQualificacaoQuestoes.invalid) {
        if (this.ligacao != null) {
          let respostas: CallCenterQualificacaoQuestaoResposta[] = [];

          this.callCenterQualificacaoQuestoes.forEach((item) => {
            if (this.formQualificacaoQuestoes.controls[item.id].value != null && this.formQualificacaoQuestoes.controls[item.id].value != "") {
              let resposta = new CallCenterQualificacaoQuestaoResposta();

              resposta.id_call_center_qualificacao_questao = item.id;
              resposta.id_call_center_ligacao = this.ligacao.id;

              if (item.tipo == "escrita") {
                /* Escrita */

                resposta.id_call_center_qualificacao_questao_alternativa = null;
                resposta.resposta = this.formQualificacaoQuestoes.controls[item.id].value;

                respostas.push(resposta);
              } else if (item.tipo == "multipla_escolha") {
                /* Múltipla Escolha */

                let valores: any[] = this.formQualificacaoQuestoes.controls[item.id].value;

                valores.forEach((subItem) => {
                  if (subItem != "") {
                    let respostaMultiplaEscolha = new CallCenterQualificacaoQuestaoResposta();

                    respostaMultiplaEscolha.id_call_center_qualificacao_questao = item.id;
                    respostaMultiplaEscolha.id_call_center_qualificacao_questao_alternativa = subItem;
                    respostaMultiplaEscolha.id_call_center_ligacao = this.ligacao.id;
                    respostaMultiplaEscolha.resposta = null;

                    respostas.push(respostaMultiplaEscolha);
                  }
                });
              } else if (item.tipo == "unica_alternativa") {
                /* Única Alternativa */

                resposta.id_call_center_qualificacao_questao_alternativa = parseInt(this.formQualificacaoQuestoes.controls[item.id].value);
                resposta.resposta = null;

                respostas.push(resposta);
              }
            }
          });

          console.log(respostas);

          let adicionarCallCenterQualificacaoQuestaoResposta: boolean = false;

          this.callCenterService.addCallCenterQualificacaoQuestaoResposta(respostas).subscribe(
            adicionarCallCenterQualificacaoQuestaoRespostas => adicionarCallCenterQualificacaoQuestaoResposta = adicionarCallCenterQualificacaoQuestaoRespostas,
            error => { console.log("Erro: " + error) },
            () => {
              if (adicionarCallCenterQualificacaoQuestaoResposta) {
                this.toastr.success("", "Qualificação salva com sucesso!");
              }
            }
          );
        }
      }
    }

    /* Visita */

    addUpdateVisita(finalizando: boolean) {
      /* Visita */

      if (this.visita != null) {
        /* Alterando */

        this.prepararEnviarDadosVisita();

        let alterarVisita: boolean = false;

        this.visitaService.update(this.visita).subscribe(
          alterarVisitas => alterarVisita = alterarVisitas,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarVisita) {
              if (finalizando) {
                this.verificarAutomacaoEventos();

                this.toastr.success("", "Visita salva com sucesso!");
              }
            }
          }
        )
      } else {
        /* Adicionando */

        this.visitaService.getProximoNumeroVisitaBySeller().subscribe(
          nroVisita => this.nroVisita = nroVisita,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.nroVisita > 0) {
              this.prepararEnviarDadosVisita();

              let adicionarVisita: boolean = false;

              this.visitaService.add(this.visita).subscribe(
                adicionarVisitas => adicionarVisita = adicionarVisitas,
                error => { console.log("Erro: " + error) },
                () => {
                  if (adicionarVisita) {
                    this.visitaService.getUltimaVisitaByCliente(this.visita.client_id).subscribe(
                      visitas => this.visita = visitas,
                      error => { console.log("Erro: " + error) },
                      () => {

                      }
                    );
                  }
                }
              );
            }
          }
        );
      }
    }

    prepararEnviarDadosVisita() {
      /* Visita */

      let visita = new Visita();

      if (this.visita != null) {
        visita.id = this.visita.id;
        visita.fullname = this.visita.fullname;
        visita.email_real = this.visita.email_real;
      }

      visita.client_id = this.cliente.client_id_app;
      visita.check_in_distance_in_meters = 0;

      if (this.visita == null) {
        visita.no_order_done_reason_id = null;
      } else {
        if (this.pedidoSelecionadoExterno == 0) {
          if (this.statusSelecionado.id == 4) { /* Venda Realizada */
            visita.no_order_done_reason_id = null;
          } else {
            if (this.redirecionarPorTempo) {
              let resultado: CallCenterResultado = this.resultados.find(resultado => resultado.flag_check_out_aberto);

              if (resultado != null) {
                visita.no_order_done_reason_id = resultado.id;
              } else {
                visita.no_order_done_reason_id = null;
              }
            } else {
              visita.no_order_done_reason_id = this.resultadoSelecionado.id;
            }
          }
        } else {
          visita.no_order_done_reason_id = null;
        }
      }

      if (this.redirecionarPorTempo) {
        visita.note = "Visita finalizada automaticamente";
      } else {
        visita.note = this.observacaoVisitaAtual;
      }

      visita.is_active = true;
      visita.seller_id = this.seller_id;
      visita.visit_id_app = this.nroVisita;
      visita.callcenter_tipo = this.ligacao.tipo;
      visita.callcenter_status = this.statusSelecionado.id;
      visita.id_call_center_ligacao = this.ligacao.id;

      this.visita = visita;
    }

    prepararReceberDadosVisita(id_visita) {
      let retorno = new Promise<void>((resolver) => {
        this.visitaService.getById(id_visita).subscribe(
          visita => this.visita = visita,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.visita != null) {
              return resolver();
            }
          }
        )
      });

      return retorno;
    }

  /* Gráfico de Venda por ? */

  alterarGraficoVendaProdutoSelecionado() {
    if (this.graficoVendaProdutosSelecionado == "Produto") {
      this.graficoVendaProdutosSelecionado = "Marca";
    } else {
      this.graficoVendaProdutosSelecionado = "Produto";
    }

    this.carregarDadosGraficoProdutoSelecionado();
  }

  carregarDadosGraficoProdutoSelecionado() {
    this.barChartLabelsProdutos = [];
    this.barChartDataProdutos[0].data = [];

    if (this.graficoVendaProdutosSelecionado == "Produto") {
      this.barChartLabelsProdutos = this.barChartLabelsProdutosPorProduto;
      this.barChartDataProdutos[0].data = this.barChartDataProdutosPorProduto;
    } else {
      this.barChartLabelsProdutos = this.barChartLabelsProdutosPorMarca;
      this.barChartDataProdutos[0].data = this.barChartDataProdutosPorMarca;
    }
  }

  /* Inside Sales */

  selecionarInsadeSales(ativo: string) {
    this.insideSales = ativo == "0" ? false : true;
  }

  /* Itens (Enviados) */

  expandirPedidosItensEnviados(i: number, id: number) {
    this.exibirFatorFinanceiroEnviados = false;

    this.pedidosItensEnviadosExpandido = i == this.pedidosItensEnviadosExpandido ? -1 : i;

    if (this.pedidosItensEnviadosExpandido > -1) {
      this.pedidosItensEnviados = this.pedidosItensEnviadosClientes.filter(pedidoItensCliente => {
        return pedidoItensCliente.order_id == id;
      });

      let qtdeProdutosFatorFinanceiro: number = this.pedidosItensEnviados.filter(pedidoItensCliente => pedidoItensCliente.fator_financeiro != null && pedidoItensCliente.fator_financeiro != 0).length;

      if (qtdeProdutosFatorFinanceiro > 0) {
        this.exibirFatorFinanceiroEnviados = true;
      }
    }
  }

  /* Itens (Menu) */

  alterarItemSelecionado(item: number) {
    this.itemSelecionado = item;

    if (this.itemSelecionado == 1) {
      // Qualificação do Cliente

      this.carregarDadosQualificacao();
    } else if (this.itemSelecionado == 2) {
      // Cesta

      this.carregarDadosCesta();
    } else if (this.itemSelecionado == 3 || this.itemSelecionado == 4) {
      // Análise de Vendas / (Histórico de Pedidos / Orçamentos)

      this.carregarDadosPedidos();
    } else if (this.itemSelecionado == 5) {
      // Histórico de Ligações

      this.carregarDadosVisitas();
    }
  }

  alterarPedidoOrcamento() {
    this.updateCliente();

    this.router.navigate(["/pedidos-cadastro/" + this.pedidoNaoEnviadoClienteHoje.order_id_app], {
      queryParams: {
        cliente: this.cliente.codigo_no_erp_do_cliente,
        ligacao: this.ligacao.id,
        vendedor: this.pedidoNaoEnviadoClienteHoje.seller_id,
        visita: this.visita.id
      }
    });
  }

  novoPedidoOrcamento() {
    if (this.configuracao.permite_lancar_pedido_inadimplente == "N") {
      /* Inadimplente */

      if (this.cliente.status_financeiro != null) {
        this.toastr.warning("", "Cliente c/ inadimplência!");

        return;
      }
    }

    this.updateCliente();

    this.router.navigate(["/pedidos-cadastro"], {
      queryParams: {
        cliente: this.cliente.codigo_no_erp_do_cliente,
        ligacao: this.ligacao.id,
        visita: this.visita.id
      }
    });
  }

  retornarDashboardOportunidades() {
    this.router.navigate(["/dashboard-oportunidades"]);
  }

  /* Itens (Não Enviados) */

  expandirPedidosItensNaoEnviados(i: number, id: number) {
    this.exibirFatorFinanceiroNaoEnviados = false;

    this.pedidosItensNaoEnviadosExpandido = i == this.pedidosItensNaoEnviadosExpandido ? -1 : i;

    if (this.pedidosItensNaoEnviadosExpandido > -1) {
      this.pedidosItensNaoEnviados = this.pedidosItensNaoEnviadosClientes.filter(pedidoItensCliente => {
        return pedidoItensCliente.order_id == id;
      });

      let qtdeProdutosFatorFinanceiro: number = this.pedidosItensNaoEnviados.filter(pedidoItensCliente => pedidoItensCliente.fator_financeiro != null && pedidoItensCliente.fator_financeiro != 0).length;

      if (qtdeProdutosFatorFinanceiro > 0) {
        this.exibirFatorFinanceiroNaoEnviados = true;
      }
    }
  }

  /* Modal */

  modalAlterarAgendamento(content: any, id: number) {
    this.callCenterAgendamentoSelecionado = this.callCenterAgendamentos.find(agendamento => agendamento.id == id);

    if (this.callCenterAgendamentoSelecionado != null) {
      if (!this.alterarLigacaoDataInvalida) {
        let dataLigacao = new Date(this.callCenterAgendamentoSelecionado.data_ligacao);

        this.alterarLigacaoData = {
          year: dataLigacao.getFullYear(),
          month: dataLigacao.getMonth() + 1,
          day: dataLigacao.getDate()
        };

        this.alterarLigacaoDataInvalida = false;

        this.alterarLigacaoHora = {
          hour: dataLigacao.getHours(),
          minute: dataLigacao.getMinutes()
        };
      }

      this.modalService.open(content, {
        ariaLabelledBy: "titulo-alterar-agendamento",
        size: "lg",
        backdrop: "static"
      }).result.then((acao) => {
        if (acao == "Salvar") {
          let dataLigacao: Date = new Date();
          let dataLigacaoInicial: Date = new Date();

          if (this.alterarLigacaoHora != null) {
            dataLigacaoInicial = new Date(this.alterarLigacaoData.year, this.alterarLigacaoData.month - 1, this.alterarLigacaoData.day, this.alterarLigacaoHora.hour, this.alterarLigacaoHora.minute, 0);

            dataLigacao = new Date(this.datePipe.transform(dataLigacaoInicial, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
          } else {
            dataLigacaoInicial = new Date(this.alterarLigacaoData.year, this.alterarLigacaoData.month - 1, this.alterarLigacaoData.day, 0, 0, 0);

            dataLigacao = new Date(this.datePipe.transform(dataLigacaoInicial, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
          }

          if (dataLigacao < new Date()) {
            this.alterarLigacaoDataInvalida = true;

            this.modalAlterarAgendamento(content, id);
          } else {
            this.alterarLigacaoDataInvalida = false;

            let alterarAgendamento: boolean = false;

            this.callCenterService.updateAgendamentoDataById(id, dataLigacao).subscribe(
              alterarAgendamentos => alterarAgendamento = alterarAgendamentos,
              error => { console.log("Erro: " + error) },
              () => {
                if (alterarAgendamento) {
                  this.callCenterAgendamentoSelecionado.data_ligacao = dataLigacaoInicial;

                  this.toastr.success("", "Agendamento atualizado com sucesso!");
                }
              }
            );
          }
        }
      }, () => {

      });
    }
  }

  modalAlterarVisita(content: any, id: number) {
    this.observacaoVisita = "";

    this.visitaSelecionada = this.visitas.find(visita => visita.id == id);

    if (this.visitaSelecionada != null) {
      this.observacaoVisita = this.visitaSelecionada.note;

      this.modalService.open(content, {
        ariaLabelledBy: "titulo-alterar-visita",
        size: "lg",
        backdrop: "static"
      }).result.then((acao) => {
        if (acao == "Salvar") {
          let alterarVisita: boolean = false;

          this.visitaService.updateObservacaoById(this.visitaSelecionada.id, this.observacaoVisita).subscribe(
            alterarVisitas => alterarVisita = alterarVisitas,
            error => { console.log("Erro: " + error) },
            () => {
              if (alterarVisita) {
                this.visitaSelecionada.note = this.observacaoVisita;

                this.toastr.success("", "Visita alterada com sucesso!");

                this.visitaSelecionada = null;
              }
            }
          );
        }
      }, () => {

      });
    }
  }

  modalNotasFiscaisSinteticas(content: any, client_document: string) {
    client_document = client_document.replace(/[.]/g, "").replace(/[/]/g, "").replace(/[-]/g, "");

    this.notaFiscalService.getNotaFiscalSinteticaByCNPJ(client_document).subscribe(
      notaFiscal => this.notasFiscais = notaFiscal,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.notasFiscais.length > 0) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-notas-fiscais-sinteticas",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhuma nota fiscal encontrada!");
        }
      }
    );
  }

  /* Números */

  somenteNumeros(element: HTMLInputElement) {
    element.value = element.value.replace(/[^0-9]/gi, "");
  }
}
