import { Time } from "@angular/common";

import { ClienteTag } from "../clientes/cliente-tag";

export class CallCenterLigacaoAnalitica {
  public id: number;
  public tipo: string;
  public id_status: number;
  public client_id: number;
  public seller_id: number;
  public data_ligacao: Date;
  public mensagem: string;
  public manual: boolean;
  public flag_atencao: string;
  public cidade: string;
  public estado: string;
  public tmp: number;
  public ult_visit: Date;
  public ult_compra: Date;
  public objetivo_venda: number;
  public realizado_venda: number;
  public qtdediasultvis: number;
  public qtdediasultcompra: number;
  public status_name: string;
  public name: string;
  public guidclientenapis: string;
  public relationship: string;
  public fullname: string;
  public pabx_ligacao_feita: boolean;
  public id_tmk: number;
  public call_center_prox_agenda: Date;
  public classificacao: string;
  public codigo_no_erp_do_cliente: string;
  public em_chamada: Time;
  public segment: string;
  public potencial_ano: number;
  public realizado_ano: number;
  public porcentagem_ano: number;
  public falta_ano: number;
  public porcentagem_venda: number;
  public realizado_venda_mesanterior: number;
  public pv: string;
  public zona_vendas: string;
  public client_document: string;
  public cliente_tags: ClienteTag[];
  public falta_vender: number;
  public qtde_realizado: number;
  public porcentagem_qtde_realizado: number;
  public valor_meta_dia: number;
  public ordem: number;

  public exibir: boolean;

  constructor() { }
}
