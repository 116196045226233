import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { CallCenterService } from '../../../core/services/call-center/call-center.service';
import { ClienteService } from '../../../core/services/clientes/cliente.service';
import { ExcelService } from '../../../core/tools/excel.service';

/* Models */

import { CallCenterConfiguracao } from '../../../core/models/call-center/call-center-configuracao';
import { CallCenterOportunidade } from '../../../core/models/call-center/call-center-oportunidade';
import { CallCenterOportunidadeClassificacao } from '../../../core/models/call-center/call-center-oportunidade-classificacao';
import { CallCenterOportunidadeFiltrar } from '../../../core/models/call-center/call-center-oportunidade-filtrar';
import { ClienteTag } from '../../../core/models/clientes/cliente-tag';
import { Estatistica } from '../../../core/models/pedidos/estatistica';

@Component({
  selector: 'app-dashboard-oportunidades',
  templateUrl: './dashboard-oportunidades.component.html',
  styleUrls: ['./dashboard-oportunidades.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class DashboardOportunidadesComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Filtros */

  formFiltro: FormGroup;

  filtroOpcoes: any[] = [
    { texto: "Classificação", valor: "0" },
    { texto: "Cliente", valor: "1" },
    { texto: "CNPJ/CPF", valor: "2" },
    { texto: "Código", valor: "3" },
    { texto: "Dias sem Compra", valor: "4" },
    { texto: "Dias sem Visita", valor: "5" },
    { texto: "Realizado Ano", valor: "6" },
    { texto: "Realizado Mês", valor: "7" },
    { texto: "Segmento", valor: "8" },
    { texto: "Tags", valor: "9" }
  ];

  enviado: boolean = false;
  filtrado: boolean = false;

  tagFiltro: any = [];
  tagFiltroErro: boolean = false;
  tagFiltroOpcoes: any = [];

  /* Ordernar (Oportunides) */

  ordernarOportunidadesOpcoes: any[] = [];

  ordernarOportunidadesOpcoesOrdem: any[] = [
    { texto: "Crescente", valor: "0" },
    { texto: "Decrescente", valor: "1" }
  ];

  ordernarOportunidadesSelecionado: string = "name";
  ordernarOportunidadesOrdemSelecionado: string = "0";

  /* Call Center (Configurações) */

  callCenterConfiguracao: CallCenterConfiguracao = null;

  /* Call Center (Oportunidades) */

  oportunidades: CallCenterOportunidade[] = [];

  qtdeClientes: number = 0;
  qtdeClientesPadrao: number = 0;
  qtdeClientesAdicionais: number = 0;

  /* Call Center (Oportunidades (Classificações)) */

  oportunidadesClassificacoes: CallCenterOportunidadeClassificacao[] = [];

  /* Estatística (Status do Pedido) */

  estatisticasClientes: Estatistica[] = [];

  /* Tags (Clientes) */

  tagCliente: any = [];
  tagClienteOpcoes: any = [];

  tagClienteIdSelecionado: number = 0;
  tagClienteNomeSelecionado: string = "";

  /* Storage */

  modelo_tela_ligacoes: number = parseInt(localStorage.getItem("modelo_tela_ligacoes"));

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private clienteService: ClienteService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      filtro: ["1"],
      classificacao: ["", Validators.required],
      cliente: ["", [Validators.required, Validators.minLength(3)]],
      cnpj_cpf: ["", Validators.required],
      codigo: ["", Validators.required],
      nro_inicial_sem_compra: ["", Validators.required],
      nro_final_sem_compra: [""],
      nro_inicial_sem_visita: ["", Validators.required],
      nro_final_sem_visita: [""],
      valor_inicial_realizado_ano: ["", Validators.required],
      valor_final_realizado_ano: [""],
      valor_inicial_realizado_mes: ["", Validators.required],
      valor_final_realizado_mes: [""],
      segmento: ["", Validators.required],
      vendedor: [""]
    });

    this.organizarFiltro();

    /* Ordernar (Oportunidades) */

    if (this.modelo_tela_ligacoes == 0) {
      /* Modelo 1 */

      this.ordernarOportunidadesOpcoes = [
        { texto: "Status", valor: "relationship" },
        { texto: "Agendamento", valor: "call_center_prox_agenda" },
        { texto: "Cliente", valor: "name" },
        { texto: "Cidade", valor: "cidade" },
        { texto: "Estado", valor: "estado" },
        { texto: "CNPJ/CPF", valor: "client_document" },
        { texto: "Segmento", valor: "segment" },
        { texto: "Vendedor(a)", valor: "fullname" },
        { texto: "Ult. Visita", valor: "ultvisita" },
        { texto: "Ult. Visita Dias", valor: "qtdediasultvisita" },
        { texto: "Ult. Compra", valor: "ultcomp" },
        { texto: "Ult. Compra Dias", valor: "qtdediasultcomp" },
        { texto: "Objetivo", valor: "objetivo_de_venda" },
        { texto: "Realizado", valor: "order_total" },
        { texto: "Falta Vender", valor: "falta_vender" },
        { texto: "Atenção", valor: "atencao" }
      ];
    } else if (this.modelo_tela_ligacoes == 1) {
      /* Modelo 2 */

      this.ordernarOportunidadesOpcoes = [
        { texto: "Status", valor: "relationship" },
        { texto: "Agendamento", valor: "call_center_prox_agenda" },
        { texto: "Cliente", valor: "name" },
        { texto: "Estado", valor: "estado" },
        { texto: "CNPJ/CPF", valor: "client_document" },
        { texto: "PV", valor: "pv" },
        { texto: "Segmento", valor: "segment" },
        { texto: "Zona", valor: "zona_vendas" },
        { texto: "Ult. Visita", valor: "ultvisita" },
        { texto: "Ult. Visita Dias", valor: "qtdediasultvisita" },
        { texto: "Ult. Compra", valor: "ultcomp" },
        { texto: "Ult. Compra Dias", valor: "qtdediasultcomp" },
        { texto: "Potêncial Ano", valor: "potencial_ano" },
        { texto: "Realizado Ano", valor: "realizado_ano" },
        { texto: "% Ano", valor: "porcentagem_ano" },
        { texto: "Falta Ano", valor: "falta_ano" },
        { texto: "Objetivo Mês", valor: "objetivo_de_venda" },
        { texto: "Realizado Mês", valor: "realizado_venda" },
        { texto: "% Mês", valor: "porcentagem_venda" },
        { texto: "Mês Anterior", valor: "realizado_venda_mesanterior" },
        { texto: "Atenção", valor: "atencao" }
      ];
    }

    this.carregarDadosOportunidades();
  }

  get f() {
    return this.formFiltro.controls;
  }

  /* Ações */

  consultarCliente(client_id_app: number) {
    this.router.navigate(["/call-center-ligacao"], {
      queryParams: {
        cliente_consulta: client_id_app
      }
    });
  }

  /* Dados */

  calcularDadosOportunidades() {
    if (this.oportunidades.length > 0) {
      /* Acompanhamento */

      this.qtdeClientes = this.oportunidades.length;
      this.qtdeClientesPadrao = this.oportunidades.filter(oportunidades => oportunidades.origem == "CARTEIRA").length;
      this.qtdeClientesAdicionais = this.oportunidades.filter(oportunidades => oportunidades.origem == "ADICIONAL").length;

      /* Estatística (Clientes) */

      this.estatisticasClientes = [];

      /* Ativo */

      let qtdeAtivo: number = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Ativo").length;
      let porcentagemAtivo: number = 0;

      if (qtdeAtivo > 0) {
        porcentagemAtivo = ((qtdeAtivo / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("ativo", "Ativo", qtdeAtivo, null, qtdeAtivo, porcentagemAtivo));

      /* Ativo batido */

      let qtdeAtivoBatido: number = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Ativo batido").length;
      let porcentagemAtivoBatido: number = 0;

      if (qtdeAtivoBatido > 0) {
        porcentagemAtivoBatido = ((qtdeAtivoBatido / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("ativo_batido", "Ativo (Batido)", qtdeAtivoBatido, null, qtdeAtivoBatido, porcentagemAtivoBatido));

      /* Ativo Premium */

      let qtdeAtivoPremium = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Ativo Premium").length;
      let porcentagemAtivoPremium = 0;

      if (qtdeAtivoPremium > 0) {
        porcentagemAtivoPremium = ((qtdeAtivoPremium / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("ativo_premium", "Ativo (Premium)", qtdeAtivoPremium, null, qtdeAtivoPremium, porcentagemAtivoPremium));

      /* Inativo */

      let qtdeInativo: number = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Inativo").length;
      let porcentagemInativo: number = 0;

      if (qtdeInativo > 0) {
        porcentagemInativo = ((qtdeInativo / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("inativo", "Inativo", qtdeInativo, null, qtdeInativo, porcentagemInativo));

      /* Prospecção */

      let qtdeProspeccao: number = this.oportunidades.filter(oportunidades => oportunidades.relationship == "Prospecção").length;
      let porcentagemProspeccao: number = 0;

      if (qtdeProspeccao > 0) {
        porcentagemProspeccao = ((qtdeProspeccao / this.qtdeClientes) * 100);
      }

      this.estatisticasClientes.push(new Estatistica("prospeccao", "Prospecção", qtdeProspeccao, null, qtdeProspeccao, porcentagemProspeccao));

      this.estatisticasClientes.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a["qtde"], b["qtde"]) * -1; /* Decrescente */
      });
    }
  }

  carregarDadosOportunidades() {
    this.carregando = true;

    /* Call Center (Configurações) */

    this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
      callCenterConfiguracao => this.callCenterConfiguracao = callCenterConfiguracao,
      error => { console.log("Erro: " + error) },
      () => {
        this.callCenterService.getOportunidadeClassificacaoByGuidClienteNapis().subscribe(
          oportunidadesClassificacoes => this.oportunidadesClassificacoes = oportunidadesClassificacoes,
          error => { console.log("Erro: " + error) },
          () => {
            /* Ordernar (Oportunidades) */

            if (this.callCenterConfiguracao != null && this.callCenterConfiguracao.mostra_classificacao) {
              let classificacao = this.ordernarOportunidadesOpcoes.find(opcao => opcao.valor == "classificacao");

              if (classificacao == null) {
                this.ordernarOportunidadesOpcoes.push(
                  { texto: "Classificação", valor: "classificacao" }
                );
              }
            }

            this.ordernarOportunidadesOrganizarOpcoes();

            this.carregando = false;
          }
        );
      }
    );
  }

  reiniciarDados() {
    this.oportunidades = [];
  }

  /* Exportar */

  exportarOportunidades() {
    this.excelService.exportAsExcelFile(this.oportunidades, "Oportunidades");
  }

  /* Filtros */

  alterarFiltro(filtro_selecionado: string) {
    this.reiniciarDados();

    this.calcularDadosOportunidades();

    let filtroAtual: string = this.formFiltro.controls["filtro"].value;

    this.formFiltro.reset();

    if (filtro_selecionado == null) {
      this.formFiltro.controls["filtro"].setValue(filtroAtual);
    } else {
      this.formFiltro.controls["filtro"].setValue(filtro_selecionado);
    }

    this.organizarFiltro();

    this.enviado = false;
    this.filtrado = false;

    this.tagFiltroErro = false;
  }

  filtrar() {
    this.enviado = true;

    if (this.formFiltro.valid) {
      if (this.formFiltro.controls["filtro"].value == "8") {
        /* Tags */

        if (this.tagFiltro.length == 0) {
          this.tagFiltroErro = true;

          return;
        } else {
          this.tagFiltroErro = false;
        }
      } else {
        this.tagFiltroErro = false;
      }

      let filtrar = new CallCenterOportunidadeFiltrar();

      if (this.formFiltro.controls["filtro"].value == "0") {
        /* Classificação */

        if (this.formFiltro.controls["classificacao"].value != "" && this.formFiltro.controls["classificacao"].value != null) {
          filtrar.classificacao = this.formFiltro.controls["classificacao"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "1") {
        /* Cliente */

        if (this.formFiltro.controls["cliente"].value != "" && this.formFiltro.controls["cliente"].value != null) {
          filtrar.cliente = this.formFiltro.controls["cliente"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "2") {
        /* CNPJ/CPF */

        if (this.formFiltro.controls["cnpj_cpf"].value != "" && this.formFiltro.controls["cnpj_cpf"].value != null) {
          let cnpj_cpf: string = this.formFiltro.controls["cnpj_cpf"].value.split(".").join("").split("-").join("").split("/").join("");

          filtrar.cnpj_cpf = cnpj_cpf;
        }
      } else if (this.formFiltro.controls["filtro"].value == "3") {
        /* Código */

        if (this.formFiltro.controls["codigo"].value != "" && this.formFiltro.controls["codigo"].value != null) {
          filtrar.codigo = this.formFiltro.controls["codigo"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "4") {
        /* Dias sem Compra */

        filtrar.nro_inicial_sem_compra = this.formFiltro.controls["nro_inicial_sem_compra"].value;

        if (this.formFiltro.controls["nro_final_sem_compra"].value != "" && this.formFiltro.controls["nro_final_sem_compra"].value != null) {
          filtrar.nro_final_sem_compra = this.formFiltro.controls["nro_final_sem_compra"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "5") {
        /* Dias sem Visita */

        filtrar.nro_inicial_sem_visita = this.formFiltro.controls["nro_inicial_sem_visita"].value;

        if (this.formFiltro.controls["nro_final_sem_visita"].value != "" && this.formFiltro.controls["nro_final_sem_visita"].value != null) {
          filtrar.nro_final_sem_visita = this.formFiltro.controls["nro_final_sem_visita"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "6") {
        /* Realizado Ano */

        filtrar.valor_inicial_realizado_ano = this.formFiltro.controls["valor_inicial_realizado_ano"].value;

        if (this.formFiltro.controls["valor_final_realizado_ano"].value != "" && this.formFiltro.controls["valor_final_realizado_ano"].value != null) {
          filtrar.valor_final_realizado_ano = this.formFiltro.controls["valor_final_realizado_ano"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "7") {
        /* Realizado Mês */

        filtrar.valor_inicial_realizado_mes = this.formFiltro.controls["valor_inicial_realizado_mes"].value;

        if (this.formFiltro.controls["valor_final_realizado_mes"].value != "" && this.formFiltro.controls["valor_final_realizado_mes"].value != null) {
          filtrar.valor_final_realizado_mes = this.formFiltro.controls["valor_final_realizado_mes"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "8") {
        /* Segmento */

        if (this.formFiltro.controls["segmento"].value != "" && this.formFiltro.controls["segmento"].value != null) {
          filtrar.segmento = this.formFiltro.controls["segmento"].value;
        }
      } else if (this.formFiltro.controls["filtro"].value == "9") {
        /* Tags */

        this.tagFiltro.forEach((item) => {
          filtrar.tags.push(item.tagValue.toLowerCase());
        });
      }

      if (this.formFiltro.controls["vendedor"].value != "" && this.formFiltro.controls["vendedor"].value != null) {
        /* Vendedor(a) */

        filtrar.vendedor = this.formFiltro.controls["vendedor"].value;
      }

      this.oportunidades = [];

      this.callCenterService.getOportunidadeByUsuario(filtrar).subscribe(
        oportunidades => this.oportunidades = oportunidades,
        error => { console.log("Erro: " + error) },
        () => {
          this.filtrado = true;

          this.oportunidades.forEach((item) => {
            if ((item.objetivo_de_venda - item.order_total) < 0) {
              item.falta_vender = 0;
            } else {
              item.falta_vender = item.objetivo_de_venda - item.order_total;
            }
          });

          if (this.modelo_tela_ligacoes == 1) {
            /* Modelo 2 */

            this.oportunidades.sort((a, b) => {
              const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

              return compare(a["ultvisita"], b["ultvisita"]);
            });

            this.ordernarOportunidadesSelecionado = "ultvisita";
          }

          this.calcularDadosOportunidades();

          if (this.oportunidades.length == 0) {
            this.toastr.error("", "Nenhuma oportunidade encontrada!");
          }
        }
      );
    }
  }

  organizarFiltro() {
    if (this.formFiltro.controls["filtro"].value == "0") {
      /* Classificação */

      this.formFiltro.get("classificacao").enable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "1") {
      /* Cliente */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").enable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "2") {
      /* CNPJ/CPF */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").enable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "3") {
      /* Código */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").enable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "4") {
      /* Dias sem Compra */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").enable();
      this.formFiltro.get("nro_final_sem_compra").enable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "5") {
      /* Dias sem Visita */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").enable();
      this.formFiltro.get("nro_final_sem_visita").enable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "6") {
      /* Realizado Ano */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").enable();
      this.formFiltro.get("valor_final_realizado_ano").enable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "7") {
      /* Realizado Mês */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").enable();
      this.formFiltro.get("valor_final_realizado_mes").enable();
      this.formFiltro.get("segmento").disable();
    } else if (this.formFiltro.controls["filtro"].value == "8") {
      /* Segmento */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").enable();
    } else if (this.formFiltro.controls["filtro"].value == "9") {
      /* Tags */

      this.formFiltro.get("classificacao").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("cnpj_cpf").disable();
      this.formFiltro.get("codigo").disable();
      this.formFiltro.get("nro_inicial_sem_compra").disable();
      this.formFiltro.get("nro_final_sem_compra").disable();
      this.formFiltro.get("nro_inicial_sem_visita").disable();
      this.formFiltro.get("nro_final_sem_visita").disable();
      this.formFiltro.get("valor_inicial_realizado_ano").disable();
      this.formFiltro.get("valor_final_realizado_ano").disable();
      this.formFiltro.get("valor_inicial_realizado_mes").disable();
      this.formFiltro.get("valor_final_realizado_mes").disable();
      this.formFiltro.get("segmento").disable();
    }

    this.tagFiltro = [];
  }

  /* Modal */

  modalTagsClientes(content: any, origem: string, id_cliente: number) {
    this.tagCliente = [];

    this.tagClienteIdSelecionado = 0;
    this.tagClienteNomeSelecionado = "";

    switch (origem) {
      case "oportunidades":
        let oportunidadeSelecionada = this.oportunidades.find(oportunidade => oportunidade.client_id_app == id_cliente);

        if (oportunidadeSelecionada != null) {
          oportunidadeSelecionada.cliente_tags.forEach((item) => {
            this.tagCliente.push({
              tagLabel: item.descricao,
              tagValue: item.descricao
            });
          });

          this.tagClienteIdSelecionado = oportunidadeSelecionada.client_id_app;
          this.tagClienteNomeSelecionado = oportunidadeSelecionada.name;
        }

        break;
      default:

    }

    if (this.tagClienteIdSelecionado != 0) {
      this.modalService.open(content, {
        ariaLabelledBy: "titulo-tags-clientes",
        size: "lg",
        backdrop: "static"
      }).result.then((acao) => {
        if (acao == "Salvar") {
          let tags: string[] = [];

          this.tagCliente.forEach((item) => {
            tags.push(item.tagValue);
          });

          let adicionarTag: boolean = false;

          this.clienteService.addTag(this.tagClienteIdSelecionado, tags).subscribe(
            adicionarTags => adicionarTag = adicionarTags,
            error => { console.log("Erro: " + error) },
            () => {
              if (adicionarTag) {
                switch (origem) {
                  case "oportunidades":
                    let oportunidadeSelecionada: CallCenterOportunidade = this.oportunidades.find(oportunidade => oportunidade.client_id_app == id_cliente);

                    if (oportunidadeSelecionada != null) {
                      oportunidadeSelecionada.cliente_tags = [];

                      tags.forEach((item) => {
                        let tag = new ClienteTag();

                        tag.client_id_app = id_cliente;
                        tag.descricao = item;

                        oportunidadeSelecionada.cliente_tags.push(tag);
                      });
                    }

                    break;
                  default:
                }

                this.toastr.success("", "Tag(s) salva(s) com sucesso!");
              }
            }
          );
        }
      }, () => {

      });
    }
  }

  /* Ordernar (Oportunidades) */

  ordernarOportunidades(opcao_selecionada: string) {
    let oportunidadesAnterior = this.oportunidades;

    this.ordernarOportunidadesSelecionado = opcao_selecionada;

    switch (this.ordernarOportunidadesOrdemSelecionado) {
      case "0":
        /* Crescente */

        oportunidadesAnterior.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a[this.ordernarOportunidadesSelecionado], b[this.ordernarOportunidadesSelecionado]);
        });

        break;
      case "1":
        /* Decrescente */

        oportunidadesAnterior.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a[this.ordernarOportunidadesSelecionado], b[this.ordernarOportunidadesSelecionado]) * -1; /* Decrescente */
        });

        break;
      default:

    }

    this.oportunidades = JSON.parse(JSON.stringify(oportunidadesAnterior));
  }

  ordernarOportunidadesOrdem(opcao_selecionada: string) {
    this.ordernarOportunidadesOrdemSelecionado = opcao_selecionada;

    this.ordernarOportunidades(this.ordernarOportunidadesSelecionado);
  }

  ordernarOportunidadesOrganizarOpcoes() {
    this.ordernarOportunidadesOpcoes.sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a["texto"], b["texto"]);
    });
  }
}
